.gridContainer {
    display: grid;
    gap: 8px;

    @media screen and (min-width: 48rem) {
        gap: 20px 20px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-template-rows: repeat(2, minmax(0, 1fr));
    }

    > div {
        min-height: 177px;
        @media screen and (min-width: 48rem) {
            min-height: 0;
        }

        &:first-of-type {
            @media screen and (min-width: 48rem) {
                min-height: 477px;
                grid-column: span 2 / span 2;
                grid-row: span 2 / span 2;
            }
        }

        &:last-of-type {
            @media screen and (min-width: 48rem) {
                grid-column: span 2 / span 2;
            }
        }
    }
}
